<template>
    <button class="btn btn-gray btn-sm mr-1 ml-1" @click="exportFile">
        <i class="fa fa-download icon-left"></i> Xuất dữ liệu
        <b-modal v-model="exporting" title="Đang tải dữ liệu" @close="close" no-close-on-esc no-close-on-backdrop no-enforce-focus>
            <div class="alert alert-warning">
                <b-spinner small variant="primary"></b-spinner>&nbsp;
                <span>Dữ liệu đang được tải xuống! Xin vui lòng đợi trong giây lát và xin đừng tắt cửa sổ này cho đến khi quá trình tải xuống hoàn tất.</span>
                {{downloadedCount}} - {{totalCount}}
                <b-progress :max="totalCount">
                    <b-progress-bar :value="downloadedCount" :label="`${((downloadedCount / totalCount) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
            </div>
        </b-modal>
    </button>
</template>

<script>
    import Export from "@/mixins/Export";
    import moment from 'moment';
    export default {
        props: ['filter', 'totalCount', 'params','prime'],
        mixins: [Export],
        data() {
            let headers = [
                    {header: 'code_local', key: 'code_local',label: 'Mã định danh', width: 30},
                    {header: 'code', key: 'code',label: 'Mã NKT', width: 30},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên', width: 30},
                    {header: 'dob', key: 'dob', label: 'Năm sinh', width: 15},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố', width: 25},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện', width: 25},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường', width: 25},
                    {header: 'race_id', key: 'race_id', label: 'Dân tộc', width: 15, desc: "Nhập định dạng chữ"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính', width: 15},
                    {header: 'types', key: 'types', label: 'Dạng tật (6 nhóm, có để đa khuyết tật)', width: 25},
                    {header: 'agent_orange_victim', key: 'agent_orange_victim', label: 'Nạn nhân da cam', width: 25},
                    {header: 'degree_disability', key: 'degree_disability', label: 'Mức độ khuyết tật', width: 25},
                    {header: 'mine_victim', key: 'mine_victim', label: 'Nạn nhân bom mìn', width: 25, desc: "Nhập giá trị số từ 1 đến 2 (Trong đó: " + this.$params.patient.mineVictimOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'start_time', key: 'start_time', label: 'Ngày bắt đầu can thiệp', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu"},
                    {header: 'rate_time', key: 'rate_time', label: 'Ngày can thiệp gần nhất', width: 25},
                    {header: 'evaluate_time', key: 'evaluate_time', label: 'Ngày đánh giá gần nhất', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp"},
                    {header: 'result', key: 'result', label: 'Kết quả đánh giá chỉ số chăm sóc', width: 25},
                    {header: 'end_time', key: 'end_time', label: 'Ngày kết thúc can thiệp', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp"},
            ];
            if (this.prime) {
                headers.unshift({header: 'client_id', key: 'client_id', label: 'Đơn vị', width: 20});
            }
            return {
                service: '/patient-care',
                fileName: `can_thiep_cham_soc`,
                headers,
                
            }
        },
        methods: {
            setupSheet: function (sheet) {
                let cols = [];
                let row = {};
                for (let i in this.headers) {
                    let header = this.headers[i];
                    cols.push(header);
                    row[header.key] = header.label;
                }
                sheet.columns = cols;
                sheet.addRow(row);

                let provinces = [];
                for (const [value] of Object.entries(this.params.provinceExport)) {
                    provinces.push(this.params.provinceExport[value]);
                }

                row = {};
                if(this.prime){
                    row['client_id'] = "Nhập định dạng chữ";
                }
                row["code_local"] = "Mã định danh"
                row["code"] = "Nhập định dạng chữ (BHYT/CCCD/CMT/Mã hồ sơ NKT - tối đa 100 ký tự)"
                row["fullname"] = "Nhập định dạng chữ (tối đa 200 ký tự)";
                row["dob"] = "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)";
                row["province_id"] = "Nhập định dạng chữ (" + provinces.toString() + ")";
                row["district_id"] = "Nhập định dạng chữ";
                row["ward_id"] = "Nhập định dạng chữ";
                row["race_id"] = "Nhập định dạng chữ";
                row["gender_id"] = "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)";
                row['types'] = "Nhập 01 hoặc nhiều giá trị số từ 1 đến 6 (Trong đó: " + this.$params.patient.typeOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row['agent_orange_victim'] = "Nhập 01 giá trị số từ 1 đến 3 (Trong đó:" + this.$params.patient.victimOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row['degree_disability'] = "Nhập 01 giá trị số từ 1 đến 4 (Trong đó: " + this.$params.patient.degreeDisabilityOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row['mine_victim'] = "Nhập giá trị số từ 1 đến 2 (Trong đó:" + this.$params.patient.mineVictimOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row['start_time'] = "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu";
                row['rate_time'] = "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp";
                row['end_time'] = "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp";
                row['result'] = "Nhập 01 giá trị số từ 1 đến 3 (Trong đó: " + this.$params.patient.resultCare.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row['evaluate_time'] = "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp";
                sheet.addRow(row);
                row.alignment = {wrapText: true};
                //ẩn dòng 1
                sheet.getRow(1).hidden = true;
                sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'bdbdbd'
                    }
                };

                // đôi đậm và wrap text
                sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(2).font = {
                    bold: true
                };
                //bôi màu
                sheet.getRow(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '92D050'
                    }
                };
                //Dòng 3
                sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(3).font = {
                    italic: true
                };
            },
            addRow(item, params) {
                let row = {
                    fullname: params.patient_id[item.patient_id].fullname.toString(),
                    code_local: params.patient_id[item.patient_id] && params.patient_id[item.patient_id].code_by_local ? params.patient_id[item.patient_id].code_by_local.toString(): '',
                    code: params.patient_id[item.patient_id].code.toString(),
                    dob: params.patient_id[item.patient_id].dob != 0 ? moment(params.patient_id[item.patient_id].dob * 1000).format('YYYY') : '',
                    province_id: params.provinces && params.provinces[params.patient_id[item.patient_id].province_id] ? params.provinces[params.patient_id[item.patient_id].province_id].name : '',
                    district_id: params.districts && params.districts[params.patient_id[item.patient_id].district_id] ? params.districts[params.patient_id[item.patient_id].district_id].name : '',
                    ward_id: params.wards && params.wards[params.patient_id[item.patient_id].ward_id] ? params.wards[params.patient_id[item.patient_id].ward_id].name : '',
                    race_id: params.patient_id[item.patient_id].race_id ? params.race[params.patient_id[item.patient_id].race_id] : '',
                    gender_id : params.patient_id[item.patient_id].gender_id != null ? this.$params.gender.find(o => o.value == parseInt(params.patient_id[item.patient_id].gender_id)).text : '',
                    types: this.getTypes(params.types[item.patient_id] ? params.types[item.patient_id] : null),
                    agent_orange_victim: params.patient_id[item.patient_id].agent_orange_victim && this.$params.patient.victimOptions.find(o => o.value == params.patient_id[item.patient_id].agent_orange_victim) ? this.$params.patient.victimOptions.find(o => o.value == params.patient_id[item.patient_id].agent_orange_victim).text : '',
                    degree_disability: params.patient_id[item.patient_id].degree_disability && this.$params.patient.degreeDisabilityOptions.find(o => o.value == params.patient_id[item.patient_id].degree_disability) ? this.$params.patient.degreeDisabilityOptions.find(o => o.value == params.patient_id[item.patient_id].degree_disability).text : '',
                    mine_victim: params.patient_id[item.patient_id].mine_victim && this.$params.patient.mineVictimOptions.find(o => o.value == params.patient_id[item.patient_id].mine_victim) ? this.$params.patient.mineVictimOptions.find(o => o.value == params.patient_id[item.patient_id].mine_victim).text : '',
                    start_time : item.start_time > 0 ? moment(item.start_time * 1000).format('DD/MM/YYYY') : '',
                    rate_time : item.rate_time > 0 ? moment(item.rate_time * 1000).format('DD/MM/YYYY') : '',
                    end_time : item.end_time > 0 ? moment(item.end_time * 1000).format('DD/MM/YYYY') : '',
                    result: item.result && this.$params.patient.resultCare.find(o => o.value == item.result) ? this.$params.patient.resultCare.find(o => o.value == item.result).text : '',
                    evaluate_time : item.evaluate_time > 0 ? moment(item.evaluate_time * 1000).format('DD/MM/YYYY') : '',
                };
                if(this.prime){
                    row['client_id'] = params.clients[item.client_id].short_name;
                }
                return row;
            },
            getTypes(type) {
                let text = "";
                if (type != null) {
                    for (let item of type) {
                        if (this.$params.patient.typeOptions.find(o => o.value == item)) {
                            text = text + this.$params.patient.typeOptions.find(o => o.value == item).text + "; ";
                        }
                    }
                }
                return text;
            },
        }
    }
</script>